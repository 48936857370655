import React, { useState, useEffect } from "react";
import CardSmall from "../../components/Cards/CardSmall/CardSmall";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useHistory,useLocation } from "react-router-dom";
import { GridContainer } from "./Landing.styled";
import config from "../../aws-exports";
import RecentlyViewedCarousel from "../../components/Carousel/RecentlyViewedCarousel/RecentlyViewedCarousel";
import { useQuery } from "@tanstack/react-query";
import { API } from "aws-amplify";
import Carousel from "../../components/Carousel/Carousel";
import { motion } from "framer-motion";
import "../../../src/font/NunitoSans-Regular.ttf";
import LearningPaths from "../../pages/Learning/LearningPaths";
import moment from "moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  dashboard,
  getMyTopics,
  getUserDetails,
  getLearningPathDetails,
  getAllCourses,
  getRecenetView,
  getWidgets,
  getAssignedContent,
  getBanners
} from "../../api/api";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EditProfile from "../MyProfile/EditProfile/EditProfile";
import SessionTimeOut from "../../components/Sessions/SessionTimeout";
import ProgressTracker from "../../components/ProgressTracker/ProgressTracker";
import MyCourseTrackerWidget from "../../components/MyCourseTrackerWidget/MyCourseTrackerWidget";
import Settings from "../../components/Header/ICICISettings/Settings";
import NorthIcon from "@mui/icons-material/North";
import { Box, Button, Fab, Modal, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Gizmos from "../../components/Gizmos/Gizmos";
import StatusViewer from "./StatusViewer";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  padding: "2rem",
  overflowX: "hidden",
  width: "90%",
  height: "90%",
};
function LandingPage() {
  const userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    mainTab: {
      width: "100%",
      typography: "body1",
      "& > :first-child": {
        boxShadow: "0px 3px 4px 0px rgba(102, 102, 102, 0.3)",
        position: "relative",
        left: "-5.3rem",
        width: "100vw",
      },
    },
    sliderDialogStyle: {
      "& > div": {
        "& > div": {
          background: "transparent",
          boxShadow: "none",
          padding: "2rem",
          margin: "0 1rem",
          border:"none"
        },
      },
    },
    wrapperDiv: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "2.5rem",
      "@media (max-width: 767px)": {
        display: "grid",
        gap: "2rem",
      },
    },
    outerDiv: {
      padding: "1rem 1rem 1rem 0rem",
      borderRadius: "5px",
      backgroundColor:
        userDetails.themeDark === "dark"
          ? "#282D37 !important"
          : theme.palette.background.paper,
      border:
        userDetails.themeDark === "dark"
          ? "1px solid transparent !important"
          : "1px solid #83838380",
    },
    innerDiv: {
      display: "flex",
      gap: "1rem",
    },
    iconStyle: {
      fontSize: "3rem!important",
      color: "var(--color-button-background)",
    },
    courseSectionStyle: {
      display: "flex",
      flexDirection: "column",
      gap: "30px",
      fontFamily: `myfontregular`,
      "@media (max-width: 1024px)": {
        marginTop: "3rem",
      },
    },
    allTopicSkeleton: {
      display: "flex",
      gap: "2rem",
      "@media (max-width: 770px)": {
        display: "block",
        marginBottom: "2rem",
        "& > span": {
          width: "100%!important",
        },
      },
    },
    mainTrackerBlock: {
      cursor: "pointer",
    },
    settingsIconStyle: {
      position: "fixed",
      right: 0,
      top: "50%",
      zIndex: 9,
      backgroundColor: "var(--color-primary)",
      borderRadius: "5px 0px 0px 5px",
      "& > div": {
        display: "flex",
      },
    },
    floatButton: {
      display: "block!important",
      backgroundImage: "linear-gradient(180deg, #007DF9 , var(--color-primary))",
      opacity: "0.8",
      boxShadow: "none!important",
      "&:hover": {
        backgroundImage: "linear-gradient(to bottom, #0061C1 , #004C99)",
      },
      "& > svg": {
        fontSize: "2.5rem",
      },
      "& > p": {
        fontSize: "1.2rem",
        textTransform: "capitalize",
        lineHeight: "6px",
        paddingBottom: "0.5rem",
      },
    },
    wrapperDivButtons: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "2.5rem",
      alignItems: "center",
      "@media (max-width: 780px)": {
        display: "none",
      },
    },
    nextButton: {
      backgroundColor: "#F7F7F7!important",
      minWidth: "1rem!important",
      height: "3.5rem!important",
      color: "black!important",
      borderRadius: "2rem!important",
      padding: "0.8rem!important",
      "& > svg": {
        fontSize: "2rem",
      },
    },
    buttonItems: {
      gap: "1.5rem",
      display: "flex",
      alignItems: "center",
      margin: "0rem 5rem",
      marginRight: "1rem", 
    },
    leftNextButton: {
      position: "absolute",
      zIndex: 99,
    },
    rightNextButton: {
      position: "absolute",
      zIndex: 99,
      right: "5.5rem",
    },
    mainButtons: {
      backgroundColor:
        userDetails.themeDark === "dark"
          ? "#323846!important"
          : "#F7F7F7!important",
      padding: "0.5rem 1rem!important",
      textTransform: "capitalize!important",
      fontSize: "1.45rem!important",
      boxShadow: "0 0px 2px 0px rgba(0, 0, 0, .2)",
      color:
        userDetails.themeDark === "dark"
          ? "#fff!important"
          : "#494949!important",
      fontFamily: `myfontregular!important`,
      fontWeight: "600!important",
      margin: "0.5rem 1rem 0.5rem 0",
    },
  }));
  const classes = useStyles();
  const [lastSeen, setLastSeen] = useState();
  const [profilePopUp, setProfilePopUp] = useState(false);
  const [bm, setBookm] = useState(false);
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const [renderObject, setRenderObject] = useState({});
  const [loading, setLoading] = useState(true);
  const handleClosePopup = () => setProfilePopUp(false);
  const history = useHistory();
  const [landingloader, setLandingLoader] = useState(false);
  const [widgetsAllTop, setWidgetsAllTop] = useState(false);
  const [continuelearningCount, setContinueLearningCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [totalCourseCount, setTotalCourseCount] = useState(0);
  const [fetchedWidgets, setFetchedWidgets] = useState(null);
  const [switchAction, setSwitchAction] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [filteredButtons, setFilteredButtons] = useState([]);
  const [announcements,setAnnouncements] = useState([]);
  const [posters,setPosters] = useState([]);
  const [postersOpen,setPostersOpen] = useState(false);
  const location = useLocation();
  const { state } = location;
  useEffect(() => {
    if (state && state.poster) {
      setPostersOpen(true);
    }
  }, [state]);
  const handleCloseposters = () => {
    setPostersOpen(false);
    history.replace({
      pathname: '/home',
      state: { poster: false }
    });
  };
  const { data: profileData, isLoading: spin } = useQuery({
    queryKey: ["user"],
    queryFn: () => getUserDetails(userDetails),
  });
  const { data: banners, isLoading: bannerLoad } = useQuery({
    queryKey: ["getBanners"],
    queryFn: () => getBanners(userDetails),
    onSuccess: (data) => {
setAnnouncements(data.banners);
setPosters(data.posters);
    },
  });
  const variants = ["h1", "h1"];
  useEffect(() => {
    const fetchData = async () => {
      try {
        let obj = {};
        setLoading(true);
        setLandingLoader(true);
        const [
          data,
          dashData,
          learningData,
          recentData,
          allcoursedata,
          switchValues,
          assigned,
        ] = await Promise.all([
          getMyTopics(userDetails),
          dashboard(userDetails),
          getLearningPathDetails(userDetails),
          getRecenetView(userDetails),
          getAllCourses(userDetails),
          getWidgets(userDetails),
          getAssignedContent(userDetails),
        ]);
        if (
          assigned?.length > 0 &&
          config?.home?.assigned &&
          switchValues.assigned
        ) {
          let styledivide = {};
          styledivide.array = assigned;
          styledivide.id = "Assigned Content";
          styledivide.class =
            config.home?.my_progress_tracker && switchValues.my_progress_tracker
              ? "item-2"
              : "item-4";
          obj.assigned = styledivide;
        } else {
          if (
            data?.continueLearning?.length > 0 &&
            config?.home?.continue_learning &&
            switchValues.continue_learning
          ) {
            let styledivide = {};
            styledivide.array = data?.continueLearning;
            styledivide.id = "Continue Learning";
            styledivide.class =
              config.home?.my_progress_tracker &&
              switchValues.my_progress_tracker
                ? "item-2"
                : "item-4";
            obj.continueLearning = styledivide;
          } else {
            if (
              recentData?.length > 0 &&
              config?.home?.recently_viewed &&
              switchValues.recently_viewed
            ) {
              let styledivide = {};
              styledivide.id = "Recently Viewed";
              styledivide.class =
                config.home?.my_progress_tracker &&
                switchValues.my_progress_tracker
                  ? "item-2"
                  : "item-4";
              obj.recentlyViewed = styledivide;
            } else {
              if (
                learningData?.length > 0 &&
                config?.home?.learning_path &&
                switchValues.learning_path
              ) {
                let styledivide = {};
                styledivide.array = learningData;
                styledivide.id = "Learning path";
                styledivide.class =
                  config.home?.my_progress_tracker &&
                  switchValues.my_progress_tracker
                    ? "item-2"
                    : "item-4";
                obj.learning = styledivide;
              } else {
                if (
                  data?.toptopics?.length > 0 &&
                  config?.home?.featured_courses &&
                  switchValues.featured_courses
                ) {
                  let styledivide = {};
                  styledivide.array = data?.toptopics;
                  styledivide.id = "Featured Courses";
                  styledivide.class =
                    config.home?.my_progress_tracker &&
                    switchValues.my_progress_tracker
                      ? "item-2"
                      : "item-4";
                  obj.toptopics = styledivide;
                } else {
                  if (
                    data?.completedCourse?.length > 0 &&
                    config?.home?.completed_course &&
                    switchValues.completed_course
                  ) {
                    let styledivide = {};
                    styledivide.array = data?.completedCourse;
                    styledivide.id = "Completed Courses";
                    styledivide.class =
                      config.home?.my_progress_tracker &&
                      switchValues.my_progress_tracker
                        ? "item-2"
                        : "item-4";
                    obj.completedCourse = styledivide;
                  } else {
                    if (
                      dashData?.popularSession?.length > 0 &&
                      config?.home?.popular_session &&
                      switchValues.popular_session
                    ) {
                      let styledivide = {};
                      styledivide.array = dashData?.popularSession;
                      styledivide.id = "Popular Sessions";
                      styledivide.class =
                        config.home?.my_progress_tracker &&
                        switchValues.my_progress_tracker
                          ? "item-2"
                          : "item-4";
                      obj.popularSession = styledivide;
                    } else {
                      if (
                        dashData?.registered?.length > 0 &&
                        config?.home?.registered_session &&
                        switchValues.registered_session
                      ) {
                        let styledivide = {};
                        styledivide.array = dashData?.registered;
                        styledivide.id = "Registered Sessions";
                        styledivide.class =
                          config.home?.my_progress_tracker &&
                          switchValues.my_progress_tracker
                            ? "item-2"
                            : "item-4";
                        obj.registered = styledivide;
                      } else {
                        if (
                          dashData?.upcoming?.length > 0 &&
                          config?.home?.upcoming_session &&
                          switchValues.upcoming_session
                        ) {
                          let styledivide = {};
                          styledivide.array = dashData?.upcoming;
                          styledivide.id = "Upcoming Sessions";
                          styledivide.class =
                            config.home?.my_progress_tracker &&
                            switchValues.my_progress_tracker
                              ? "item-2"
                              : "item-4";
                          obj.upcoming = styledivide;
                        } else {
                          if (
                            dashData?.completed?.length > 0 &&
                            config?.home?.completed_session &&
                            switchValues.completed_session
                          ) {
                            let styledivide = {};
                            styledivide.array = dashData?.completed;
                            styledivide.id = "Completed Sessions";
                            styledivide.class =
                              config.home?.my_progress_tracker &&
                              switchValues.my_progress_tracker
                                ? "item-2"
                                : "item-4";
                            obj.completed = styledivide;
                          } else {
                            if (
                              dashData?.completed?.length > 0 &&
                              config?.home?.completed_session &&
                              switchValues.completed_session
                            ) {
                              let styledivide = {};
                              styledivide.array = dashData?.completed;
                              styledivide.id = "Completed Sessions";
                              styledivide.class =
                                config.home?.my_progress_tracker &&
                                switchValues.my_progress_tracker
                                  ? "item-2"
                                  : "item-4";
                              obj.completed = styledivide;
                            } else {
                              if (
                                allcoursedata?.allcourses?.length > 0 &&
                                config?.home?.all_courses &&
                                switchValues.all_courses
                              ) {
                                let styledivide = {};
                                styledivide.array = allcoursedata?.allcourses;
                                styledivide.id = "All Courses";
                                styledivide.class =
                                  config.home?.my_progress_tracker &&
                                  switchValues.my_progress_tracker
                                    ? "item-2"
                                    : "item-4";
                                obj.allcourses = styledivide;
                              } else {
                                if (
                                  allcoursedata?.objects?.length > 0 &&
                                  config?.home?.content_repository &&
                                  switchValues.content_repository
                                ) {
                                  let styledivide = {};
                                  styledivide.array = allcoursedata?.objects;
                                  styledivide.id = "Content Repository";
                                  styledivide.class =
                                    config.home?.my_progress_tracker &&
                                    switchValues.my_progress_tracker
                                      ? "item-2"
                                      : "item-4";
                                  obj.allcontent = styledivide;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        if (
          assigned?.length === 0 &&
          data?.continueLearning?.length === 0 &&
          recentData?.length === 0 &&
          learningData?.length === 0 &&
          data?.toptopics?.length === 0 &&
          data?.completedCourse?.length === 0 &&
          dashData?.popularSession?.length === 0 &&
          dashData?.registered?.length === 0 &&
          dashData?.popularSession?.length === 0 &&
          dashData?.upcoming?.length === 0 &&
          dashData?.completed?.length === 0 &&
          allcoursedata?.allcourses?.length === 0 &&
          allcoursedata?.objects?.length === 0
        ) {
          if (
            config.home?.my_progress_tracker &&
            switchValues.my_progress_tracker
          ) {
            let styledivide = {};
            styledivide.id = "My Courses Progress Tracker";
            styledivide.class = "item-4";
            obj.progressTracker = styledivide;
          }
        }
        if (
          (assigned?.length > 0 &&
            config?.home?.assigned &&
            switchValues.assigned) ||
          (data?.continueLearning?.length > 0 &&
            config?.home?.continue_learning &&
            switchValues.continue_learning) ||
          (recentData?.length > 0 &&
            config?.home?.recently_viewed &&
            switchValues.recently_viewed) ||
          (learningData?.length > 0 &&
            config?.home?.learning_path &&
            switchValues.learning_path) ||
          (data?.toptopics?.length > 0 &&
            config?.home?.featured_courses &&
            switchValues.featured_courses) ||
          (data?.completedCourse?.length > 0 &&
            config?.home?.completed_course &&
            switchValues.completed_course) ||
          (dashData?.popularSession?.length > 0 &&
            config?.home?.popular_session &&
            switchValues.popular_session) ||
          (dashData?.registered?.length > 0 &&
            config?.home?.registered_session &&
            switchValues.registered_session) ||
          (dashData?.upcoming?.length > 0 &&
            config?.home?.upcoming_session &&
            switchValues.upcoming_session) ||
          (dashData?.completed?.length > 0 &&
            config?.home?.completed_session &&
            switchValues.completed_session) ||
          (allcoursedata?.allcourses?.length > 0 &&
            config?.home?.all_courses &&
            switchValues.all_courses) ||
          (allcoursedata?.objects?.length > 0 && config?.home?.all_topics)
        ) {
          if (
            config.home?.my_progress_tracker &&
            switchValues.my_progress_tracker
          ) {
            let styledivide = {};
            styledivide.id = "My Courses Progress Tracker";
            styledivide.class = "item-3";
            obj.progressTracker = styledivide;
          }
        }
        if (
          assigned?.length > 0 &&
          config?.home?.assigned &&
          switchValues.assigned
        ) {
          if (
            data?.continueLearning?.length > 0 &&
            config?.home?.continue_learning &&
            switchValues.continue_learning
          ) {
            let styledivide = {};
            styledivide.id = "Continue Learning";
            styledivide.class = "item-4";
            styledivide.array = data?.continueLearning;
            obj.continueLearning = styledivide;
          }
        }
        if (
          (assigned?.length > 0 &&
            config?.home?.assigned &&
            switchValues.assigned) ||
          (data?.continueLearning?.length > 0 &&
            config?.home?.continue_learning &&
            switchValues.continue_learning)
        ) {
          if (
            recentData?.length > 0 &&
            config?.home?.recently_viewed &&
            switchValues.recently_viewed
          ) {
            let styledivide = {};
            styledivide.id = "Recently Viewed";
            styledivide.class = "item-4";
            obj.recentlyViewed = styledivide;
          }
        }
        if (
          (assigned?.length > 0 &&
            config?.home?.assigned &&
            switchValues.assigned) ||
          (data?.continueLearning?.length > 0 &&
            config?.home?.continue_learning &&
            switchValues.continue_learning) ||
          (recentData?.length > 0 &&
            config?.home?.recently_viewed &&
            switchValues.recently_viewed)
        ) {
          if (
            learningData?.length > 0 &&
            config?.home?.learning_path &&
            switchValues.learning_path
          ) {
            let styledivide = {};
            styledivide.id = "Learning path";
            styledivide.array = learningData;
            styledivide.class = "item-4";
            obj.learning = styledivide;
          }
        }
        if (
          (assigned?.length > 0 &&
            config?.home?.assigned &&
            switchValues.assigned) ||
          (data?.continueLearning?.length > 0 &&
            config?.home?.continue_learning &&
            switchValues.continue_learning) ||
          (recentData?.length > 0 &&
            config?.home?.recently_viewed &&
            switchValues.recently_viewed) ||
          (learningData?.length > 0 &&
            config?.home?.learning_path &&
            switchValues.learning_path)
        ) {
          if (
            data?.toptopics?.length > 0 &&
            config?.home?.featured_courses &&
            switchValues.featured_courses
          ) {
            let styledivide = {};
            styledivide.array = data?.toptopics;
            styledivide.id = "Featured Courses";
            styledivide.class = "item-4";
            obj.toptopics = styledivide;
          }
        }
        if (
          (assigned?.length > 0 &&
            config?.home?.assigned &&
            switchValues.assigned) ||
          (data?.continueLearning?.length > 0 &&
            config?.home?.continue_learning &&
            switchValues.continue_learning) ||
          (recentData?.length > 0 &&
            config?.home?.recently_viewed &&
            switchValues.recently_viewed) ||
          (learningData?.length > 0 &&
            config?.home?.learning_path &&
            switchValues.learning_path) ||
          (data?.toptopics?.length > 0 &&
            config?.home?.featured_courses &&
            switchValues.featured_courses)
        ) {
          if (
            data?.completedCourse?.length > 0 &&
            config?.home?.completed_course &&
            switchValues.completed_course
          ) {
            let styledivide = {};
            styledivide.array = data?.completedCourse;
            styledivide.id = "Completed Courses";
            styledivide.class = "item-4";
            obj.completedCourse = styledivide;
          }
        }
        if (
          (assigned?.length > 0 &&
            config?.home?.assigned &&
            switchValues.assigned) ||
          (data?.continueLearning?.length > 0 &&
            config?.home?.continue_learning &&
            switchValues.continue_learning) ||
          (recentData?.length > 0 &&
            config?.home?.recently_viewed &&
            switchValues.recently_viewed) ||
          (learningData?.length > 0 &&
            config?.home?.learning_path &&
            switchValues.learning_path) ||
          (data?.toptopics?.length > 0 &&
            config?.home?.featured_courses &&
            switchValues.featured_courses) ||
          (data?.completedCourse?.length > 0 &&
            config?.home?.completed_course &&
            switchValues.completed_course)
        ) {
          if (
            dashData?.popularSession?.length > 0 &&
            config?.home?.continue_learning &&
            switchValues.continue_learning
          ) {
            let styledivide = {};
            styledivide.array = dashData?.popularSession;
            styledivide.id = "Popular Sessions";
            styledivide.class = "item-4";
            obj.popularSession = styledivide;
          }
        }
        if (
          (assigned?.length > 0 &&
            config?.home?.assigned &&
            switchValues.assigned) ||
          (data?.continueLearning?.length > 0 &&
            config?.home?.continue_learning &&
            switchValues.continue_learning) ||
          (recentData?.length > 0 &&
            config?.home?.recently_viewed &&
            switchValues.recently_viewed) ||
          (learningData?.length > 0 &&
            config?.home?.learning_path &&
            switchValues.learning_path) ||
          (data?.toptopics?.length > 0 &&
            config?.home?.featured_courses &&
            switchValues.featured_courses) ||
          (data?.completedCourse?.length > 0 &&
            config?.home?.completed_course &&
            switchValues.completed_course) ||
          (dashData?.popularSession?.length > 0 &&
            config?.home?.popular_session &&
            switchValues.popular_session)
        ) {
          if (
            dashData?.registered?.length > 0 &&
            config?.home?.registered_session &&
            switchValues.registered_session
          ) {
            let styledivide = {};
            styledivide.array = dashData?.registered;
            styledivide.id = "Registered Sessions";
            styledivide.class = "item-4";
            obj.registered = styledivide;
          }
        }
        if (
          (assigned?.length > 0 &&
            config?.home?.assigned &&
            switchValues.assigned) ||
          (data?.continueLearning?.length > 0 &&
            config?.home?.continue_learning &&
            switchValues.continue_learning) ||
          (recentData?.length > 0 &&
            config?.home?.recently_viewed &&
            switchValues.recently_viewed) ||
          (learningData?.length > 0 &&
            config?.home?.learning_path &&
            switchValues.learning_path) ||
          (data?.toptopics?.length > 0 &&
            config?.home?.featured_courses &&
            switchValues.featured_courses) ||
          (data?.completedCourse?.length > 0 &&
            config?.home?.completed_course &&
            switchValues.completed_course) ||
          (dashData?.popularSession?.length > 0 &&
            config?.home?.popular_session &&
            switchValues.popular_session) ||
          (dashData?.registered?.length > 0 &&
            config?.home?.registered_session &&
            switchValues.registered_session)
        ) {
          if (
            dashData?.upcoming?.length > 0 &&
            config?.home?.upcoming_session &&
            switchValues.upcoming_session
          ) {
            let styledivide = {};
            styledivide.array = dashData?.upcoming;
            styledivide.id = "Upcoming Sessions";
            styledivide.class = "item-4";
            obj.upcoming = styledivide;
          }
        }
        if (
          (assigned?.length > 0 &&
            config?.home?.assigned &&
            switchValues.assigned) ||
          (data?.continueLearning?.length > 0 &&
            config?.home?.continue_learning &&
            switchValues.continue_learning) ||
          (recentData?.length > 0 &&
            config?.home?.recently_viewed &&
            switchValues.recently_viewed) ||
          (learningData?.length > 0 &&
            config?.home?.learning_path &&
            switchValues.learning_path) ||
          (data?.toptopics?.length > 0 &&
            config?.home?.featured_courses &&
            switchValues.featured_courses) ||
          (data?.completedCourse?.length > 0 &&
            config?.home?.completed_course &&
            switchValues.completed_course) ||
          (dashData?.popularSession?.length > 0 &&
            config?.home?.popular_session &&
            switchValues.popular_session) ||
          (dashData?.registered?.length > 0 &&
            config?.home?.registered_session &&
            switchValues.registered_session) ||
          (dashData?.upcoming?.length > 0 &&
            config?.home?.upcoming_session &&
            switchValues.upcoming_session)
        ) {
          if (
            dashData?.completed?.length > 0 &&
            config?.home?.completed_session &&
            switchValues.completed_session
          ) {
            let styledivide = {};
            styledivide.array = dashData?.completed;
            styledivide.id = "Completed Sessions";
            styledivide.class = "item-4";
            obj.completed = styledivide;
          }
        }
        if (
          (assigned?.length > 0 &&
            config?.home?.assigned &&
            switchValues.assigned) ||
          (data?.continueLearning?.length > 0 &&
            config?.home?.continue_learning &&
            switchValues.continue_learning) ||
          (recentData?.length > 0 &&
            config?.home?.recently_viewed &&
            switchValues.recently_viewed) ||
          (learningData?.length > 0 &&
            config?.home?.learning_path &&
            switchValues.learning_path) ||
          (data?.toptopics?.length > 0 &&
            config?.home?.featured_courses &&
            switchValues.featured_courses) ||
          (data?.completedCourse?.length > 0 &&
            config?.home?.completed_course &&
            switchValues.completed_course) ||
          (dashData?.popularSession?.length > 0 &&
            config?.home?.popular_session &&
            switchValues.popular_session) ||
          (dashData?.registered?.length > 0 &&
            config?.home?.registered_session &&
            switchValues.registered_session) ||
          (dashData?.upcoming?.length > 0 &&
            config?.home?.upcoming_session &&
            switchValues.upcoming_session) ||
          (dashData?.completed?.length > 0 &&
            config?.home?.completed_session &&
            switchValues.completed_session)
        ) {
          if (
            allcoursedata?.allcourses?.length > 0 &&
            config?.home?.all_courses &&
            switchValues.all_courses
          ) {
            let styledivide = {};
            styledivide.array = allcoursedata?.allcourses;
            styledivide.id = "All Courses";
            styledivide.class = "item-4";
            obj.allcourses = styledivide;
          }
        }
        if (
          assigned.length > 0 ||
          data?.continueLearning?.length > 0 ||
          recentData?.length > 0 ||
          learningData?.length > 0 ||
          data?.toptopics?.length > 0 ||
          data?.completedCourse?.length > 0 ||
          dashData?.popularSession?.length > 0 ||
          dashData?.registered?.length > 0 ||
          dashData?.upcoming?.length > 0 ||
          dashData?.upcoming?.length > 0 ||
          dashData?.completed?.length > 0 ||
          allcoursedata?.allcourses?.length > 0
        ) {
          if (
            allcoursedata?.objects?.length > 0 &&
            config?.home?.content_repository &&
            switchValues.content_repository
          ) {
            let styledivide = {};
            styledivide.array = allcoursedata?.objects;
            styledivide.id = "Content Repository";
            styledivide.class = "item-4";
            obj.allcontent = styledivide;
          }
        }
        const buttonDataMap = {
          assigned: assigned?.length > 0,
          continue_learning: data?.continueLearning?.length > 0,
          my_progress_tracker: allcoursedata?.objects?.length > 0,
          learning_path: learningData?.length > 0,
          featured_courses: data?.toptopics?.length,
          completed_course: data?.completedCourse?.length > 0,
          popular_session: dashData?.popularSession?.length > 0,
          registered_session: dashData?.registered?.length > 0,
          completed_session: dashData?.completed?.length > 0,
          all_courses: allcoursedata?.allcourses?.length > 0,
          all_topics: allcoursedata?.objects?.length > 0,
          content_repository: allcoursedata?.objects?.length > 0,
        };
        const isDataPresent = (buttonValue) => {
          return buttonDataMap[buttonValue];
        };
        const filteredButtons = buttons.filter(
          (button) =>
            switchValues[button.value] &&
            config.home[button.value] &&
            isDataPresent(button.value)
        );
        setFilteredButtons(filteredButtons);
        setWidgetsAllTop(switchValues.all_topics);
        setCompletedCount(data?.completedCourse?.length);
        setContinueLearningCount(data?.continueLearning?.length);
        setTotalCourseCount(allcoursedata?.allcourses?.length);
        setFetchedWidgets(switchValues);
        setRenderObject(obj);
        setLandingLoader(false);
        setLoading(false);
      } catch (error) {}
    };
    fetchData();
  }, [switchAction, bm]);
  useEffect(() => {
    if (!userDetails) {
      if (config.aws_org_id == "AXIATA") {
        window.location.href = `${config.auth_url}oauth2/authorize?response_type=code&client_id=${config.aws_user_pools_web_client_id}&redirect_uri=${config.aws_cloudfront_lurl}&scope=email+openid+profile+aws.cognito.signin.user.admin&identity_provider=Cognito User Pool"`;
      } else {
        history.push("/");
      }
    }
    if (userDetails?.uData?.la != null) {
      setLastSeen(
        moment(parseInt(userDetails.uData.la)).format("hh:mm,  DD MMM YYYY")
      );
    } else {
      setLastSeen(false);
    }
  }, []);
  const carouselName = (key) => {
    if (key === "assigned") {
      return `${userDetails?.languagejson?.Home_Page?.assigned ||
        "Assigned Content"}`;
    }
    if (key === "continueLearning") {
      return `${userDetails?.languagejson?.Home_Page?.Continue_Learning ||
        "Continue Learning"}`;
    }
    if (key === "learningData") {
      return `${userDetails?.languagejson?.Home_Page?.Learning_path ||
        "Learning path"}`;
    }
    if (key === "toptopics") {
      return `${userDetails?.languagejson?.Home_Page?.Featured_Courses ||
        "Featured Courses"}`;
    }
    if (key === "continueLearning") {
      return `${userDetails?.languagejson?.Home_Page?.Continue_Learning ||
        "Continue Learning"}`;
    }
    if (key === "completedCourse") {
      return `${userDetails?.languagejson?.Home_Page?.Completed_Courses ||
        "Completed Courses"}`;
    }
    if (key === "popularSession") {
      return `${userDetails?.languagejson?.Home_Page?.Popular_Session ||
        "Popular Session"}`;
    }
    if (key === "registered") {
      return `${userDetails?.languagejson?.Home_Page?.Registered_Sessions ||
        "Registered Session"}`;
    }
    if (key === "upcoming") {
      return `${userDetails?.languagejson?.Home_Page?.Upcoming_Session ||
        "Upcoming Session"}`;
    }
    if (key === "completed") {
      return `${userDetails?.languagejson?.Home_Page?.Completed_Sessions ||
        "Completed Session"}`;
    }
    if (key === "allcourses") {
      return `${userDetails?.languagejson?.Home_Page?.All_Courses ||
        "All courses"}`;
    }
    if (key === "allcontent") {
      return `${userDetails?.languagejson?.Home_Page?.Content_Repository ||
        "Content Repository"}`;
    }
  };
  useEffect(() => {
    if (profileData) {
      let userData = { ...userDetails };
      userData.uData = profileData;
      dispatch(awsSignIn(userData));
    }
  }, []);
  useEffect(() => {
    getUserDetailsFetch(userDetails);
    if (config.profileSet) {
      checkProfileUpdate();
    }
  }, []);
  async function getUserDetailsFetch(userDetails) {
    try {
      const requestBody = {
        emailid: userDetails.email,
        schema: config.schema,
        address: config.profileSet,
        ur_id: userDetails.uData.ur_id,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getUserDetails?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );
      const { body } = response;
      let userData = { ...userDetails };
      userData.uData = body[0];
      dispatch(awsSignIn(userData));
    } catch (err) {
      throw err;
    }
  }
  async function checkProfileUpdate() {
    const jsonString = JSON.stringify({
      oid: config.aws_org_id,
      schema: config.schema,
      ur_id: userDetails.uData?.ur_id,
    });
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/checkProfileUpdate",
        bodyParam
      );
      if (response.body[0].profile_update === true) {
        setProfilePopUp(true);
      }
    } catch (error) {
      return false;
    }
  }
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const showButton = scrollY > 300;
      setIsVisible(showButton);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [startIndex, setStartIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const buttons = [
    { Dname: `${userDetails?.languagejson?.Home_Page?.assigned ||
        "Assigned Content"}`, Dispname: "Assigned Content", value: "assigned" },
    { Dname: `${userDetails?.languagejson?.Home_Page?.Continue_Learning ||
        "Continue Learning"}`,Dispname: "Continue Learning", value: "continue_learning" },
    { Dname: `${userDetails?.languagejson?.Course_View?.My_Courses_Progress_Tracker || "My Courses Progress Tracker"}`,Dname: "My Courses Progress Tracker", value: "my_progress_tracker" },
    { Dname: `${userDetails?.languagejson?.Home_Page?.Recently_Viewed ||
            "Recently Viewed"}`, Dispname: "Recently Viewed", value: "recently_viewed" },
    { Dname: `${userDetails?.languagejson?.Home_Page?.Learning_path ||
        "Learning path"}`,Dispname: "Learning path", value: "learning_path" },
    { Dname: `${userDetails?.languagejson?.Home_Page?.Completed_Courses ||
        "Completed Courses"}`,Dispname: "Completed Courses", value: "completed_course" },
    { Dname: `${userDetails?.languagejson?.Home_Page?.Featured_Courses ||
        "Featured Courses"}`,Dispname: "Featured Courses", value: "featured_courses" },
    { Dname: `${userDetails?.languagejson?.Home_Page?.Popular_Session ||
        "Popular Session"}`,Dispname: "Popular Sessions", value: "popular_session" },
    { Dname: `${userDetails?.languagejson?.Home_Page?.Registered_Sessions ||
        "Registered Session"}`,Dispname: "Registered Sessions", value: "registered_session" },
    { Dname: `${userDetails?.languagejson?.Home_Page?.Completed_Sessions ||
        "Completed Session"}`,Dispname: "Completed Sessions", value: "completed_session" },
    { Dname: `${userDetails?.languagejson?.Home_Page?.All_Courses ||
        "All courses"}`,Dispname: "All Courses", value: "all_courses" },
    { Dname: `${userDetails?.languagejson?.Home_Page?.Content_Repository ||
        "Content Repository"}`,Dispname: "Content Repository", value: "content_repository" },
    { Dname: `${userDetails?.languagejson?.Home_Page?.All_Topics ||
                  "All Topics"}`,Dispname: "All Topics", value: "all_topics" },
  ];
  const handleRightClick = () => {
    if (startIndex + itemsPerPage < filteredButtons.length) {
      setStartIndex(startIndex + itemsPerPage);
    }
  };
  const handleLeftClick = () => {
    if (startIndex - itemsPerPage >= 0) {
      setStartIndex(startIndex - itemsPerPage);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const newItemsPerPage = Math.floor(windowWidth / 200);
    setItemsPerPage(newItemsPerPage);
  }, [windowWidth]);
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const offset = section.getBoundingClientRect().top + window.scrollY - 80;
      window.scroll({
        behavior: "smooth",
        top: offset,
      });
    }
  };
  return (
    <motion.div>
      <SessionTimeOut active={active} setActive={setActive} />
      <div className={classes.wrapperDivButtons}>
        <div className={classes.leftNextButton}>
          <Button onClick={handleLeftClick} className={classes.nextButton}>
            <ArrowBackIosNewIcon />
          </Button>
        </div>
        {landingloader ? (
          <div className={classes.buttonItems}>
            <Skeleton
              variant="text"
              style={{ fontSize: "2rem", width: "20rem", height: "3.9rem" }}
            />
            <Skeleton
              variant="text"
              style={{ fontSize: "2rem", width: "20rem", height: "3.9rem" }}
            />
            <Skeleton
              variant="text"
              style={{ fontSize: "2rem", width: "20rem", height: "3.9rem" }}
            />
            <Skeleton
              variant="text"
              style={{ fontSize: "2rem", width: "20rem", height: "3.9rem" }}
            />
          </div>
        ) : (
          <div className={classes.buttonItems}>
            {filteredButtons
              .slice(startIndex, startIndex + itemsPerPage)
              .map((button, index) => (
                <Button
                  key={index}
                  className={classes.mainButtons}
                  onClick={() => scrollToSection(button.Dispname)}
                >
                  {button.Dname}
                </Button>
              ))}
          </div>
        )}
        <div className={classes.rightNextButton}>
          <Button onClick={handleRightClick} className={classes.nextButton}>
            <ArrowForwardIosIcon />
          </Button>
        </div>
      </div>
      <div className={classes.wrapperDiv}>
        <div className={classes.outerDiv}>
          <div className={classes.innerDiv}>
            <div
              style={{ borderLeft: "2px solid var(--color-button-background)" }}
            ></div>
            <AccountCircleIcon className={classes.iconStyle} />
            <div>
              {spin ? (
                <Skeleton
                  variant="text"
                  style={{ fontSize: "2rem", width: "20rem" }}
                />
              ) : (
                <p style={{ fontWeight: "bold" }}>
                  {` ${userDetails?.languagejson?.Home_Page?.Welcome ||
                    "Welcome"}, ${profileData?.first_name} ${
                    profileData?.last_name
                  }`}
                </p>
              )}
              {lastSeen && (
                <div style={{ gap: "4px", display: "flex", marginTop: "4px" }}>
                  <AccessTimeIcon
                    style={{ fontSize: "1.3rem", color: "gray" }}
                  />
                  <p style={{ fontSize: "12px" }}>{`${userDetails?.languagejson
                    ?.Home_Page?.Last_seen_at ||
                    "Last seen at"} ${lastSeen}`}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        {config?.home?.my_progress_tracker && (
          <div
            onClick={() => history.push("/learningAnalytics")}
            className={classes.mainTrackerBlock}
          >
            <MyCourseTrackerWidget
              completedCount={completedCount}
              totalCourseCount={totalCourseCount}
              loading={loading}
            />
          </div>
        )}
        {landingloader ? (
          <div className={classes.settingsIconStyle}>
            <Skeleton
               variant="circular"
              width={25}
              height={25}
              animation="wave"
              style={{ margin: "10px" }}
            />
          </div>
        ) : (
          <div className={classes.settingsIconStyle}>
            <Settings
              fetchedWidgets={fetchedWidgets}
              setFetchedWidgets={setFetchedWidgets}
              switchAction={switchAction}
              setSwitchAction={setSwitchAction}
            />
          </div>
        )}
      </div>
      {config.aws_org_id !== "NSECORP" && <Gizmos banners ={announcements} bannerLoad={bannerLoad}/>}
      {landingloader ? (
        <>
          {variants.map((variant) => (
            <Typography component="h1" variant={variant}>
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={250}
                animation="wave"
                style={{ marginBottom: "3rem" }}
              />
            </Typography>
          ))}
        </>
      ) : (
        <>
          <GridContainer className={classes.divGrid}>
            {!loading &&
              Object.keys(renderObject).map((key) => {
                if (key === "progressTracker") {
                  return (
                    <div
                      key={key}
                      className={renderObject[key].class}
                      id={renderObject[key]?.id}
                    >
                      <ProgressTracker
                        cmpcount={completedCount || 0}
                        cncount={continuelearningCount || 0}
                        totalCourseCount={totalCourseCount}
                      />
                    </div>
                  );
                }
                if (key === "recentlyViewed") {
                  return (
                    <div
                      key={key}
                      className={renderObject[key].class}
                      id={renderObject[key]?.id}
                    >
                      <RecentlyViewedCarousel
                        classDefine={renderObject[key].class}
                      />
                    </div>
                  );
                }
                if (key === "learning") {
                  return (
                    <div
                      key={key}
                      className={renderObject[key].class}
                      id={renderObject[key]?.id}
                    >
                      <LearningPaths
                        page="Home"
                        learningData={renderObject[key].array}
                        isLoading={loading}
                        classDefine={renderObject[key].class}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={key}
                      className={renderObject[key].class}
                      id={renderObject[key]?.id}
                    >
                      <Carousel
                        heading={carouselName(key)}
                        cardData={renderObject[key].array}
                        loading={loading}
                        setBookm={setBookm}
                        bm={bm}
                        classDefine={renderObject[key].class}
                      />
                    </div>
                  );
                }
              })}
          </GridContainer>
        </>
      )}
      {landingloader ? (
        <>
          {variants.map((variant) => (
            <Typography component="h1" variant={variant}>
              <div className={classes.allTopicSkeleton}>
                <Skeleton
                  style={{ height: 100, width: "24%" }}
                  animation="wave"
                  variant="rectangular"
                />
                <Skeleton
                  style={{ height: 100, width: "24%" }}
                  animation="wave"
                  variant="rectangular"
                />
                <Skeleton
                  style={{ height: 100, width: "24%" }}
                  animation="wave"
                  variant="rectangular"
                />
                <Skeleton
                  style={{ height: 100, width: "24%" }}
                  animation="wave"
                  variant="rectangular"
                />
              </div>
            </Typography>
          ))}
        </>
      ) : (
        <div id="All Topics">
          {config.aws_org_id !== "BODHICO" &&
            config?.home?.all_topics &&
            widgetsAllTop && (
              <CardSmall
                heading={`${userDetails?.languagejson?.Home_Page?.All_Topics ||
                  "All Topics"}`}
              />
            )}
        </div>
      )}
      {profilePopUp && (
        <Modal
          open={profilePopUp}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <EditProfile
              handleClosePopup={handleClosePopup}
              getUserDetailsFetch={getUserDetailsFetch}
            />
          </Box>
        </Modal>
      )}
      <div style={{ position: "fixed", bottom: 35, right: 8, zIndex: 999 }}>
        {isVisible && (
          <Fab
            color="primary"
            aria-label="scroll-to-top"
            onClick={scrollToTop}
            className={classes.floatButton}
          >
            <NorthIcon />
            <p>Top</p>
          </Fab>
        )}
      </div>
      {postersOpen && !bannerLoad && banners?.posters?.length > 0 && (
    <Modal
                open={postersOpen}
                onClose={handleCloseposters}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.sliderDialogStyle}>
                    <div style={{ height: "100%" }}>
                        <StatusViewer media={banners.posters} bannerLoad={bannerLoad} handleCloseposters={handleCloseposters} />
                    </div>
                </Box>
            </Modal>
        )}
    </motion.div>
  );
}
export default LandingPage;
